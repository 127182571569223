import React from 'react'
import FeaturesStyles from '../../styles/Features.module.sass'

/**
 * Display a section with Features
 */
const Features = () => {
  return (
    <div className={`force-full-width ${FeaturesStyles.features || ''}`}>
      <div
        className={`container`}
      >
        <div className={`columns ${FeaturesStyles.columns || ''}`}>
          <div className={`column is-one-quarter`}>
            <img src="/media/icon-toilets.svg" alt="toilet solutions" />
            <em>Toilet Solutions</em>
            <p>Our products are made with superior quality materials to ensure your bathroom products continue to perform.</p>
          </div>

          <div className={`column is-one-quarter`}>
            <img src="/media/icon-anti-bacterial-materials.svg" alt="toilet solutions" />
            <em>Hygienic Materials</em>
            <p>Our products are made with superior quality materials to ensure your bathroom products continue to perform.</p>
          </div>

          <div className={`column is-one-quarter`}>
            <img src="/media/icon-long-lasting.svg" alt="toilet solutions" />
            <em>Long Lasting</em>
            <p>Our products are made with superior quality materials to ensure your bathroom products continue to perform.</p>
          </div>

          <div className={`column is-one-quarter`}>
            <img src="/media/icon-ireland.svg" alt="toilet solutions" />
            <em>Irish Company</em>
            <p>Our products are made with superior quality materials to ensure your bathroom products continue to perform.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Features