import React from 'react'
import { Link } from 'gatsby'
import FeaturedServicesStyles from '../../styles/FeaturedServices.module.sass'

/**
 * Display a section with Featured Services
 */
const FeaturedServices = () => {
  return (
    <div
      className={`force-full-width ${FeaturedServicesStyles.featuredServices || ''}`}
    >
      <ul className='container'>
        <li>Sports Club Toilets</li>
        <li>School Bathrooms</li>
        <li>Leisure Centre Toilets</li>
        <li>Shopping Centre Toilets</li>
        <li>Healthcare Washrooms</li>
        <li>Pub & Restaurant Toilets</li>
      </ul>
    </div>
  )
}

export default FeaturedServices