import React from 'react'
import { Link } from 'gatsby'
import Phone from './Phone'
import LargeCTAStyles from '../../styles/LargeCTA.module.sass'

/**
 * Display a call-to-action section
 *
 * @param {string} title
 * @param {string} text
 * @param {string} buttonText Button label
 */
const LargeCTA = props => {
  return (
    <div className={`large-cta ${LargeCTAStyles.cta}`}>
      <div className={`buttons ${LargeCTAStyles.buttons}`}>
        <Phone class="button is-link is-ghost phone with-icon icon-white" />
        <Link to="/contact/" className="button is-white">
          {props.buttonText || 'Get a Quote'}
        </Link>
      </div>
    </div>
  )
}

export default LargeCTA